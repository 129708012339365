import { Model, Attribute, BelongsTo, HasMany, RelationHandling } from './decorators';
import { BaseApiModel } from './base';
import { ExtractionTypeAdditionalExtractorType, ExtractionTypeType, TargetType } from '@parashift/shared/types';
import { Target } from './target.model';
import { ExtractionTypeAdditionalExtractor } from './extraction-type-additional-extractor.model';
import { NameValueSet } from './document-type-field.model';
import { Locale } from './../interfaces/locale.interface';
import { BusinessSector } from './../interfaces/business-sector.interface';

const ExtractionTypeStatus = {
  draft: $localize `:@@common.draft:Draft` as 'draft',
  dev: $localize `:@@common.dev:Dev` as 'dev',
  internal: $localize `:@@common.internal:Internal` as 'internal',
  public: $localize `:@@common.public:Public` as 'public',
};
type ExtractionTypeStatus = (typeof ExtractionTypeStatus)[keyof typeof ExtractionTypeStatus];
export { ExtractionTypeStatus };

const InputParameters = {
  coordinates: $localize `:@@common.coordinates:Coordinates` as 'coordinates',
  directions: $localize `:@@common.directions:Directions` as 'directions',
  patterns: $localize `:@@common.patterns:Patterns` as 'patterns',
  case_insensitive: $localize `:@@common.case_insensitive:Case Insensitive` as 'case_insensitive',
  neighbor_params: $localize `:@@common.neighbor_params:Neighbor Params` as 'neighbor_params',
  vector: $localize `:@@common.vector:Vector` as 'vector',
  region_size: $localize `:@@common.region_size:Region Size` as 'region_size',
  prefix: $localize `:@@common.prefix:Prefix` as 'prefix',
  threshold: $localize `:@@common.threshold:Threshold` as 'threshold',
  preprocess_parameters: $localize `:@@common.preprocess_parameters:Preprocess Parameters` as 'preprocess_parameters',
  training_parameters: $localize `:@@common.training_parameters:Training Parameters` as 'training_parameters',
  checkbox_settings: $localize `:@@common.checkbox_settings:Checkbox Settings` as 'checkbox_settings',
  input_arguments: $localize `:@@common.input_arguments:Input Arguments` as 'input_arguments',
  types: $localize `:@@common.types:Types` as 'types',
};
type InputParameters = (typeof InputParameters)[keyof typeof InputParameters];
export { InputParameters };

export enum ScaleTiers {
  BASIC = 'BASIC',
  STANDARD_1 = 'STANDARD_1',
  PREMIUM_1 = 'PREMIUM_1',
  BASIC_GPU = 'BASIC_GPU',
  BASIC_TPU = 'BASIC_TPU',
  CUSTOM = 'CUSTOM'
}

export enum PrefixBases {
  'document-field-types' = 'document-field-types',
  'document-type-fieldsets' = 'document-type-fieldsets',
  'classifications' = 'classifications',
  'page-stream-separation' = 'page-stream-separation',
}

export interface ExtractionTypePlainModel {
  id: string;
  business_sectors: BusinessSector[];
  created_at: string;
  description: string;
  input_arguments: string;
  input_parameters: InputParameters[];
  locales: Locale[];
  premium: boolean;
  preprocess_image_url: string;
  preprocess_parameters: NameValueSet[];
  preprocess_prefix_base: PrefixBases;
  preprocess_scale_tier: ScaleTiers;
  status: ExtractionTypeStatus;
  tags: string[];
  target_id: string;
  title: string;
  training_image_url: string;
  training_parameters: NameValueSet[];
  training_prefix_base: PrefixBases;
  training_scale_tier: ScaleTiers;
  updated_at: string;
  version: string;

  target: Target['plainModel'] | Partial<Target['plainModel']>;
  extraction_type_additional_extractors: (ExtractionTypeAdditionalExtractor['plainModel'] | Partial<ExtractionTypeAdditionalExtractor['plainModel']>)[];
}

@Model({ type: ExtractionTypeType })
export class ExtractionType extends BaseApiModel<ExtractionTypePlainModel> {

  @Attribute()
  business_sectors: BusinessSector[];

  @Attribute({ readonly: true })
  created_at: string;

  @Attribute()
  description: string;

  @Attribute()
  input_arguments: object;

  @Attribute()
  input_parameters: InputParameters[];

  @Attribute()
  locales: Locale[];

  @Attribute()
  premium: boolean;

  @Attribute()
  preprocess_image_url: string;

  @Attribute()
  preprocess_parameters: NameValueSet[];

  @Attribute()
  preprocess_prefix_base: PrefixBases;

  @Attribute()
  preprocess_scale_tier: ScaleTiers;

  @Attribute()
  status: ExtractionTypeStatus;

  @Attribute()
  tags: string[];

  @Attribute()
  target_id: number;

  @Attribute()
  title: string;

  @Attribute()
  training_image_url: string;

  @Attribute()
  training_parameters: NameValueSet[];

  @Attribute()
  training_prefix_base: PrefixBases;

  @Attribute()
  training_scale_tier: ScaleTiers;

  @Attribute({ readonly: true })
  updated_at: string;

  @Attribute()
  version: string;

  // Includes / Relations

  @BelongsTo({ class: TargetType })
  target: Target;

  @RelationHandling('destroy')
  @HasMany({ class: ExtractionTypeAdditionalExtractorType, sidepostable: true })
  extraction_type_additional_extractors: (ExtractionTypeAdditionalExtractor | Partial<ExtractionTypeAdditionalExtractor>)[];

  get plainModel() {
    const model = this.toHash() as ExtractionTypePlainModel;
    model.target_id = this.target_id ? String(this.target_id) : undefined;
    model.input_arguments = this.getInputArguments();
    model.input_parameters = this.input_parameters || [];
    model.extraction_type_additional_extractors = this.extraction_type_additional_extractors
      .sort((a, b) => a.step - b.step)
      .map(o => o.plainModel);

    return model;
  }

  set plainModel(model) {
    this.customUpdates = {
      input_arguments: setInputArguments,
    };

    this.setPlainModel(model);
  }

  getInputArguments() {
    if (!this.input_arguments) {
      return '';
    }
    return JSON.stringify(this.input_arguments);
  }
}

function setInputArguments(existingModel: BaseApiModel, updatedPlainModel: BaseApiModel['plainModel'], key: string) {
  if (updatedPlainModel[key]) {
    existingModel[key] = JSON.parse(updatedPlainModel[key]);
  } else {
    existingModel[key] = null;
  }
}
